













































































































































































































































































































































































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import { required, email, maxLength } from "vuelidate/lib/validators";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import {mapActions, mapGetters} from "vuex";
import {CompanyData, CountryCodes} from "@/types";
import createImage from "@/mixins/CreateImage";
import router from "@/router";
import {checkVAT, countries} from "jsvat";
import DatePicker from "@/mixins/DatePicker";
import * as ibantools from "ibantools";
import {errorMessage} from "@/utils/messages-handlers";
import i18n from "@/i18n/i18n";

export default Vue.extend({
  name: "company-detail" as string,

  components: {
    Layout,
    vSelect
  },
  mixins: [createImage],

  data() {
    return {
      title: "Company data",
      edit: true,
      isShownCompanyLogo: false,
      file: "",
      name: "Example company",
      entityName: "business s.r.o",
      selectedCountry: null,
      countries: [],
      billingStreet: "Kosicka 10",
      billingZip: "05504",
      billingCity: "Kosice",
      billingVat: "SK0554225",
      billingBankName: "OTP",
      billingIco: "123513",
      billingIban: "SK05 5042 2351 2235 12351",
      billingSwift: "OS12351",
      billingEmail: "billing@example.com",
      contactPerson: "Jakub Novak",
      contactPersonPhone: "089 494 6093",
      item: false,
      isSubmitted: false,
      lang: DatePicker,
      i18: i18n,
    }
  },

  //@ts-ignore
  validations: {
    name: { required },
    billingEmail: { required, email },
    billingZip: { required, maxLength: maxLength(10)  },
    contactPerson: { required },
    entityName: { required },
    billingCity: { required },
    billingIco: { required },
    billingStreet: { required },
    contactPersonPhone: {
      checkPhoneNumber(contactPersonPhone) {
        if(contactPersonPhone === null || contactPersonPhone === '') return true;
        return (/^[+\d](?:.*\d|\s*)(?:.*\d|\s*)?$/.test(contactPersonPhone));
      }
    },
    selectedCountry: { required },
    billingSwift: {
      checkSwift(billingSwift) {
        if(billingSwift === null || billingSwift === '') return true;
        return ibantools.isValidBIC(billingSwift);
      }
    },
    billingVat: {
      checkVat(vatId){
        return this.checkVatId(vatId);
      }
    },
    billingIban: {
      checkIban(iban){
        if(iban === null || iban === '') return true;
        return ibantools.isValidIBAN(iban.replace(/\s/g, ''));
      }
    }
  },


  async mounted(): Promise<void> {
    // await this.setCompany(this.companyId);
    // if (!!this.company.image_last_updated) {
    //   await this.setImage({id: this.company.id, imageType: "venue_owner"});
    // }
    // await this.setCountries();
    // this.countries = this.getCountries;
  },

  computed: {
    // ...mapGetters("venueModule", {
    //   getCountries: "GET_COUNTRY_CODES"
    // }),
    // ...mapGetters("userModule", {
    //   hasEditCompanyPermissions: "GET_EDIT_COMPANY_PERMISSION"
    // }),
    // ...mapGetters('userModule', {
    //   user: "GET_LOGGED_USER",
    // }),
    // ...mapGetters("registerModule", {
    //   existCompanyWithVat: "EXIST_COMPANY_WITH_VAT",
    //   existCompanyWithName: "EXIST_COMPANY_WITH_NAME",
    //   existCompanyWithBin: "EXIST_COMPANY_WITH_BIN",
    // }),
    formatBillingIban: {
      get() {
        return this.billingIban
      },
      set(value: any) {
        this.billingIban = value.toUpperCase().replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim()
      }
    },
    formatBillingSwift: {
      get() {
        return this.billingSwift
      },
      set(value: any) {
        this.billingSwift = value.toUpperCase()
      }
    },
    formatBillingZip: {
      get() {
        return this.billingZip
      },
      set(value: any) {
        this.billingZip = value.toUpperCase()
      }
    },
    insertCompanyImage(): string {
      if (!this.company.image) return "";
      return `data:image/jpeg;charset=utf-8;base64, ${this.company.image}`
    },
    formatImage(): string {
      return this.item.split(',')[1];
    },
    selectedCountryCode(): CountryCodes | string {
      const selectedCountry = this.getCountries.find(c => c.id === this.company.billing_country_id);
      if (!selectedCountry) return "";
      return selectedCountry;
    },
    selectedCountryCodeName(): string {
      return this.selectedCountryCode.name;
    }
  },

  methods: {
    ...mapActions("companyModule", {
      setCompany: "SET_COMPANY",
      setCompanies: 'FETCH_COMPANIES',
      updateCompanyData: "UPDATE_COMPANY"
    }),
    ...mapActions("globalModule", {
      setImage: "FETCH_ENTITY_IMAGE"
    }),
    ...mapActions("venueModule", {
      setCountries: "SET_COUNTRY_CODES"
    }),
    ...mapActions("userModule", {
      checkLoggedUserRolePermission: "CHECK_LOGGED_USER_ROLE_PERMISSION"
    }),
    ...mapActions("registerModule", {
      filterCompanyVat: "FILTER_COMPANY_VAT",
      filterCompanyName: "FILTER_COMPANY_NAME",
      filterCompanyBin: "FILTER_COMPANY_BIN",
      resetCompanyFilters: "RESET_COMPANY_FILTERS",
    }),
    previewFile(item, e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.createImage(files[0],".company-image");
    },
    fetchCompaniesImages(): void {
      for (let i = 0; i < this.companies.length; i++) {
        const company: CompanyData = this.companies[i];

        if (!!company.image_last_updated) {
          this.setImage({id: company.id, imageType: "venue_owner", multi: true});
        }
      }
    },
    async updateCompany(): Promise<void> {
      this.resetCompanyFilters();
      this.isSubmitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) return;

      const payload: object = {
        name: this.name,
        contact: this.contactPerson,
        phone: this.contactPersonPhone,
        email: this.billingEmail,
        legal_entity_name: this.entityName,
        billing_name: this.name,
        billing_street: this.billingStreet,
        billing_city: this.billingCity,
        billing_zip: this.billingZip,
        billing_country_id: this.selectedCountry.id,
        billing_ico: this.billingIco,
        billing_vat: this.billingVat,
        billing_bank_name: this.billingBankName,
        billing_iban: this.billingIban,
        billing_swift: this.billingSwift,
        billing_email: this.billingEmail
      }

      if (this.item) {
        payload["picture"] = this.formatImage;
      }
      if(this.company.name.trim() !== this.name.trim()){
        await this.filterCompanyName(this.name.trim());
      }
      if(this.company.billing_vat){
        if(this.company.billing_vat.trim() !== this.billingVat.trim()){
          await this.filterCompanyVat(this.billingVat.trim());
        }
      }
      if(this.company.billing_ico.trim() !== this.billingIco.trim()){
        await this.filterCompanyBin(this.billingIco.trim());
      }
      if (this.existCompanyWithVat) {
        errorMessage(`${this.$t('Company with VAT')} ${this.billingVat} ${this.$t('is already registered.')}`,5000);
        return;
      }
      if (this.existCompanyWithName) {
        errorMessage(`${this.$t('Company with name')} ${this.name} ${this.$t('is already registered.')}`,5000);
        return;
      }
      if (this.existCompanyWithBin) {
        errorMessage(`${this.$t('Company with business identification number')} ${this.billingIco} ${this.$t('is already registered.')}`,5000);
        return;
      }
      await this.updateCompanyData(payload)
      //@ts-ignore
      if (payload.picture) {
        await this.setImage({id: this.company.id, imageType: "venue_owner"});
        await this.setCompanies();
        this.fetchCompaniesImages();
      }
      this.edit = false;
    },
    DateFormat(timestamp){
      let d = new Date(timestamp * 1000);
      let day = d.getDate();
      let month = d.getMonth() + 1;
      let year = d.getFullYear();
      return `${day}.${month}.${year}`
    },
    checkVatId(vatId){
      let vatObject = checkVAT(vatId, countries);
      if(vatId.length == 0){
        return true
      } else {
        return vatObject.isValidFormat;
      }
    }
  },

  watch: {
    async companyId(value): Promise<void> {
      if (!!this.company.image_last_updated) {
        await this.setImage({id: this.company.id, imageType: "venue_owner"});
      }
    },
    company(value) {
      this.name = !!this.company.name ? this.company.name : "";
      this.entityName = !!this.company.legal_entity_name ? this.company.legal_entity_name : "";
      this.selectedCountry = this.selectedCountryCode;
      this.billingStreet = !!this.company.billing_street ? this.company.billing_street : "";
      this.billingZip = !!this.company.billing_zip ? this.company.billing_zip : "";
      this.billingCity = !!this.company.billing_city ? this.company.billing_city : "";
      this.billingVat = !!this.company.billing_vat ? this.company.billing_vat : "";
      this.billingBankName = !!this.company.billing_bank_name ? this.company.billing_bank_name : "";
      this.billingIco = !!this.company.billing_ico ? this.company.billing_ico : "";
      this.billingIban = !!this.company.billing_iban ? this.company.billing_iban : "";
      this.billingSwift = !!this.company.billing_swift ? this.company.billing_swift : "";
      this.billingEmail = !!this.company.billing_email ? this.company.billing_email : "";
      this.contactPerson = !!this.company.contact ? this.company.contact : "";
      this.contactPersonPhone = !!this.company.phone ? this.company.phone : "";
      this.item = !!this.company.image_last_updated ? this.insertCompanyImage : false;
    },
    edit(value){
      if(value){
        this.selectedCountry = this.selectedCountryCode;
      }
    }
  }
});
